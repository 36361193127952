export class Links {
  static APP_URL = import.meta.env.VITE_URL;
  static API_LINK = import.meta.env.VITE_API_LINK;
  static WS_LINK = import.meta.env.VITE_WS_LINK;
  static APP_PROTOCOL = import.meta.env.VITE_PROTOCOL;
  static APP_DOMAIN = import.meta.env.VITE_DOMAIN;
  static URL_TERMS_AND_CONDITIONS = import.meta.env.URL_TERMS_AND_CONDITIONS;
  static URL_CONFIDENTIALITY_POLICY = import.meta.env.URL_CONFIDENTIALITY_POLICY;

  static PUBLIC_AGENDAS_URL(subdomain, agendaSlug) {
    return `${this.APP_PROTOCOL}${subdomain}.${this.APP_DOMAIN}/agenda/${agendaSlug}`;
  }

  static SERVICE_PAYMENT_URL(subdomain, paymentSecret) {
    return `${this.APP_PROTOCOL}${subdomain}.${this.APP_DOMAIN}/plata-serviciu?paymentSecret=${paymentSecret}`;
  }
  static SUBSCRIPTION_PAYMENT_URL(subdomain, paymentSecret) {
    return `${this.APP_PROTOCOL}${subdomain}.${this.APP_DOMAIN}/plata-abonament?paymentSecret=${paymentSecret}`;
  }

  static AGENDA_PLANNER_URL(subdomain, slug) {
    return `${this.APP_PROTOCOL}${subdomain}.${this.APP_DOMAIN}/planificator/${slug}`;
  }

  static PREVIEW_URL(subdomain) {
    return `${this.APP_PROTOCOL}${subdomain}.${this.APP_DOMAIN}`;
  }

  static EMBEDDED_URL(subdomain) {
    return `${this.APP_PROTOCOL}${subdomain}.${this.APP_DOMAIN}/schedule-embedded`;
  }

  static REGISTER_USER_COMPANY_ADMIN() {
    return `${this.API_LINK}/users/register-user-company-admin`;
  }
  static REGISTER_USER_COMPANY_USER = `${this.API_LINK}/users/register-user-company-user`;

  // ???: ..
  static REQ_REGISTER() {
    return `${this.API_LINK}/users/register`;
  }

  // ???: ..
  static REQ_REGISTER_SECOND(id, token) {
    return `${this.API_LINK}/users/register-second/${id}?token=${token}`;
  }

  static REQUEST_TOKEN() {
    return `${this.API_LINK}/users/request-token`;
  }

  static LOGIN_GOOGLE = `${this.API_LINK}/users/login-google`;
  static LOGIN_FACEBOOK = `${this.API_LINK}/users/login-facebook`;

  static REFRESH_TOKEN() {
    return `${this.API_LINK}/users/refresh-token`;
  }

  static RESET_PASS_REQUEST() {
    return `${this.API_LINK}/users/reset-password/request`;
  }

  static RESET_PASS_CHECK_TOKEN() {
    return `${this.API_LINK}/users/reset-password/check-token`;
  }

  static RESET_PASS_UPDATE_CREDENTIALS() {
    return `${this.API_LINK}/users/reset-password/update-credentials`;
  }

  // TODO: ???: better ask for old password too?
  static CHANGE_PASSWORD() {
    return `${this.API_LINK}/users/change-password`;
  }

  static CHECK_UNIQUE_EMAIL(email) {
    return `${this.API_LINK}/users/check-unique-email/${email}`;
  }

  static UPDATE_USER() {
    return `${this.API_LINK}/users/update-user`;
  }

  static COMPANY_USERS = `${this.API_LINK}/users/company-users`;
  static COMPANY_USER(id) {
    return `${this.API_LINK}/users/company-user/${id}`;
  }

  static COMPANY_OWNER_USER(id) {
    return `${this.API_LINK}/users/company-owner/${id}`;
  }

  static USER_NAME(email) {
    return `${this.API_LINK}/users/user-name/${email}`;
  }

  static COMPANY_LINK() {
    return `${this.API_LINK}/companies`;
  }

  static COMPANY_CHECK_SUBDOMAIN(subdomain) {
    return `${this.API_LINK}/companies/check-subdomain/${subdomain}`;
  }

  static SIGNUP_WIZARD_CHECK_SUBDOMAIN(subdomain) {
    return `${this.API_LINK}/companies/signup-wizard/check-subdomain/${subdomain}`;
  }

  static COMPANY_SUBSCRIPTION_LIMIT() {
    return `${this.API_LINK}/companies/subscription-limit`;
  }

  static COMPANY_UPDATE_SUBDOMAIN() {
    return `${this.API_LINK}/companies/subdomain`;
  }

  static COMPANY_SAVE_INVOICE_PROVIDER_KEYS() {
    return `${this.API_LINK}/companies/settings/invoice/save-keys`;
  }

  static COMPANY_COMPLETE_SIGNUP_WIZARD = `${this.API_LINK}/companies/complete-signup-wizard`;
  static COMPANY_COMPLETE_SIGNUP_WIZARD_EXTERNAL_AUTH = `${this.API_LINK}/companies/complete-signup-wizard-external-auth`;
  static COMPANY_COMPLETE_INITIAL_TOUR = `${this.API_LINK}/companies/complete-initial-tour`;

  static COMPANY_REQUEST_SUBSCRIPTION_CHANGE() {
    return `${this.API_LINK}/companies/request-subscription-plan`;
  }

  static COMPANY_CLEAR_FUTURE_SUBSCRIPTION = `${this.API_LINK}/companies/clear-future-subscription`;

  static COMPANY_CANCEL_SUBSCRIPTION = `${this.API_LINK}/companies/cancel-subscription`;

  static COMPANY_DOWNGRADE_SUBSCRIPTION = `${this.API_LINK}/companies/downgrade-subscription`;

  static COMPANY_UPGRADE_SUBSCRIPTION = `${this.API_LINK}/companies/upgrade-subscription`;

  static COMPANY_CHANGE_SUBSCRIPTION_ON_TRIAL = `${this.API_LINK}/companies/change-subscription-on-trial`;

  static COMPANY_SELECT_SUBSCRIPTION_PLAN_IN_SIGNUP_WIZARD() {
    return `${this.API_LINK}/companies/select-subscription-plan-in-signup-wizard`;
  }

  static COMPANY_SETTINGS_NOTIFY_BEFORE() {
    return `${this.API_LINK}/company-settings/notify-before`;
  }

  static COMPANY_SETTING_NOTIFY_BEFORE(id, companySettingName) {
    return `${this.API_LINK}/company-settings/${id}/${companySettingName}/notify-before`;
  }

  static COMPANY_SETTINGS_NOTIFY_AFTER() {
    return `${this.API_LINK}/company-settings/notify-after`;
  }

  static COMPANY_SETTING_NOTIFY_AFTER(id, companySettingName) {
    return `${this.API_LINK}/company-settings/${id}/${companySettingName}/notify-after`;
  }

  static COMPANY_SETTING_NOTIFY_OWNER(id, companySettingName) {
    return `${this.API_LINK}/company-settings/${id}/${companySettingName}/notify-owner`;
  }

  static COMPANY_SETTING_REMINDER_TEXT(id, companySettingName) {
    return `${this.API_LINK}/company-settings/${id}/${companySettingName}/reminder-text`;
  }

  static COMPANY_SETTING_FORM(id, companySettingName) {
    return `${this.API_LINK}/company-settings/${id}/${companySettingName}/form`;
  }
  static COMPANY_SETTING_PUBLIC_WEBSITE(id, companySettingName) {
    return `${this.API_LINK}/company-settings/${id}/${companySettingName}/company-website`;
  }
  static COMPANY_SETTING_ENABLED_PUBLIC_WEBSITE_ACCESS(id, companySettingName) {
    return `${this.API_LINK}/company-settings/${id}/${companySettingName}/company-website-access`;
  }
  static COMPANY_SETTING_WEBSITE_SUBSCRIPTION_DESCRIPTION(id, companySettingName) {
    return `${this.API_LINK}/company-settings/${id}/${companySettingName}/company-website-subscription-description`;
  }
  static COMPANY_SETTING_ENABLED_PUBLIC_WEBSITE_EMBEDDED_ACCESS(id, companySettingName) {
    return `${this.API_LINK}/company-settings/${id}/${companySettingName}/company-website-embedded-access`;
  }
  static COMPANY_SETTING_GET_PUBLIC_WEBSITE_DATA(companyId) {
    return `${this.API_LINK}/company-settings/company-website/${companyId}`;
  }
  static COMPANY_SETTING_GET_PUBLIC_WEBSITE_SCHEDULE_EMBEDDED(companyId) {
    return `${this.API_LINK}/company-settings/company-website/schedule-embedded/${companyId}`;
  }
  static COMPANY_SETTING_OBJECT_PLACEHOLDER(id, companySettingName) {
    return `${this.API_LINK}/company-settings/${id}/${companySettingName}/object-placeholder`;
  }
  static COMPANY_SETTING_NOTIFICATION_TIME_INTERVAL(id, companySettingName) {
    return `${this.API_LINK}/company-settings/${id}/${companySettingName}/notif-time-interval`;
  }
  static COMPANY_SETTING_TIMEZONE(id, companySettingName) {
    return `${this.API_LINK}/company-settings/${id}/${companySettingName}/timezone`;
  }
  static COMPANY_SETTING_ACCESS_INVOICE_PROVIDER_SETTINGS(id, companySettingName) {
    return `${this.API_LINK}/company-settings/${id}/${companySettingName}/access-invoice-provider-settings`;
  }

  static COMPANY_SETTING_PLANNER() {
    return `${this.API_LINK}/company-settings/planner`;
  }

  static EVENT_TYPES_CHECK_IF_EMPTY() {
    return `${this.API_LINK}/event-types/check-if-empty`;
  }

  static EVENT_TYPES_LINK() {
    return `${this.API_LINK}/event-types`;
  }
  static EVENT_TYPE_LINK(id) {
    return `${this.API_LINK}/event-types/${id}`;
  }
  static EVENT_TYPE_CHECK_ALLOW_CREATE_LINK() {
    return `${this.API_LINK}/event-types/check-allow-create`;
  }
  static GET_AVAILABLE_SPOTS_PER_OPTION() {
    return `${this.API_LINK}/event-types/get-available-spot-per-option`;
  }
  static GET_FOR_PUBLIC_AGENDA_MULTIPLE_SERVICES_PER_EVENT() {
    return `${this.API_LINK}/event-types/get-for-public-agenda-multiple-services-per-event`;
  }

  static EVENTS_LINK() {
    return `${this.API_LINK}/events`;
  }

  static EVENTS_FROM_ALL_AGENDAS_LINK() {
    return `${this.API_LINK}/events/all-agendas`;
  }

  static EVENT_LINK(id) {
    return `${this.API_LINK}/events/${id}`;
  }
  static EVENT_DUPLICATE_LINK(id) {
    return `${this.API_LINK}/events/${id}/duplicate`;
  }
  static EVENT_GET_SMS_HISTORY(eventId) {
    return `${this.API_LINK}/events/sms-history?eventId=${eventId}`;
  }
  static EVENT_CHANGE_PAYMENT_STATUS_LINK(id) {
    return `${this.API_LINK}/events/${id}/update-payment-status`;
  }

  static OBJECTS_LINK() {
    return `${this.API_LINK}/event-objects`;
  }

  static OBJECT_LINK(id) {
    return `${this.API_LINK}/event-objects/${id}`;
  }

  static CONTACT_REQUEST_ACCESS_PERSONAL_SPACE_LINK() {
    return `${this.API_LINK}/event-objects/request-access-personal-space`;
  }

  static CONTACT_ACCESS_PERSONAL_SPACE_LINK() {
    return `${this.API_LINK}/event-objects/access-personal-space`;
  }

  static SEARCH_OBJECTS_AUTOCOMPLETE_LINK() {
    return `${this.API_LINK}/search/event-objects/autocomplete`;
  }

  static SEARCH_OBJECTS_LETTERS_LINK() {
    return `${this.API_LINK}/event-objects/search-letters`;
  }

  static EVENT_NOTIFICATIONS_LINK() {
    return `${this.API_LINK}/event-notifications`;
  }

  static EVENT_NOTIFICATIONS_GET_CONVENIENT_NOTIF_TIME() {
    return `${this.API_LINK}/event-notifications/get-convenient-notif-time`;
  }

  // ???: for now.. needs change
  static AGENDAS_LINK() {
    return `${this.API_LINK}/agendas`;
  }

  static AGENDA_LINK(id) {
    return `${this.API_LINK}/agendas/${id}`;
  }

  static GET_INFO_FOR_PUBLIC_AGENDA(agendaSlug) {
    return `${this.API_LINK}/agendas/get-info-for-public-agenda/${agendaSlug}`;
  }

  static GET_AVAILABLE_DATES_FOR_MONTH() {
    return `${this.API_LINK}/agendas/get-available-dates-for-month`;
  }
  static GET_AVAILABLE_SPOTS_FOR_DAY() {
    return `${this.API_LINK}/agendas/get-available-spots-for-day`;
  }

  static GET_AVAILABLE_ALL_DAY_SPOTS_FOR_MONTH() {
    return `${this.API_LINK}/agendas/get-available-all-day-spots-for-month`;
  }

  static CREATE_EVENT_ON_PUBLIC_AGENDA(agendaSlug) {
    return `${this.API_LINK}/agendas/create-event-on-public-agenda/${agendaSlug}`;
  }

  static GET_PLANNER(agendaSlug) {
    return `${this.API_LINK}/agendas/get-planner/${agendaSlug}`;
  }

  static GET_PLANNER_EVENTS() {
    return `${this.API_LINK}/agendas/get-planner/events`;
  }

  static GET_PLANNER_AUTH() {
    return `${this.API_LINK}/agendas/get-planner/auth`;
  }

  static GET_ADDITIONAL_NEW_AGENDA_PAYMENT_ORDER_STATUS() {
    return `${this.API_LINK}/agendas/additional-new-agenda-payment-order-status`;
  }

  static FORM_SUBMISSION(formType, formUrlId) {
    return `${this.API_LINK}/form-submissions/${formType}/event/${formUrlId}`;
  }

  static FORM_SUBMISSION_SUBSCRIPTION(formType, formUrlId) {
    return `${this.API_LINK}/form-submissions/${formType}/subscription/${formUrlId}`;
  }

  static FORM_SUBMISSIONS_LINK() {
    return `${this.API_LINK}/form-submissions`;
  }

  static FORM_SUBMISSION_FILE(id) {
    return `${this.API_LINK}/form-submissions/${id}/file`;
  }

  static IMPORT_OBJECTS_LINK() {
    return `${this.API_LINK}/import-event-objects`;
  }

  static IMPORT_OBJECT_LINK(id) {
    return `${this.API_LINK}/import-event-objects/${id}`;
  }

  static DOWNLOAD_DEMO_CSV() {
    return `${this.API_LINK}/import-event-objects/download-csv`;
  }

  static SUBSCRIPTION_PLANS() {
    return `${this.API_LINK}/subscription-plans`;
  }

  static STANDARD_SUBSCRIPTION_PLANS() {
    return `${this.API_LINK}/subscription-plans/standard`;
  }

  static STANDARD_SUBSCRIPTION_PLANS_FOR_SIGNUP_WIZZARD() {
    return `${this.API_LINK}/subscription-plans/sign-up-wizzard/standard`;
  }

  static INVOICE_LINK() {
    return `${this.API_LINK}/invoices`;
  }

  static INVOICE_DOWNLOAD_INVOICE_LINK() {
    return `${this.API_LINK}/invoices/download-invoice`;
  }

  static INVOICE_PAYMENT_ORDER_LINK() {
    return `${this.API_LINK}/invoices/payment-order`;
  }

  static INVOICE_PAYMENT_ORDER_STATUS() {
    return `${this.API_LINK}/invoices/payment-order-status`;
  }

  static INVOICE_NEW_PAYMENT_METHOD_PAYMENT_ORDER_STATUS() {
    return `${this.API_LINK}/invoices/new-payment-method-payment-order-status`;
  }

  static INVOICE_NEW_PAYMENT_METHOD_LINK() {
    return `${this.API_LINK}/invoices/new-payment-method-validation`;
  }

  static INVOICE_GET_INVOICE_ON_PUBLIC_AGENDA_LINK = `${this.API_LINK}/invoices/get-invoice-on-public-agenda`;

  static FILES_LINK() {
    return `${this.API_LINK}/files`;
  }

  static FILES_PUBLIC_LINK(accessHash) {
    return `${this.API_LINK}/files/${accessHash}`;
  }

  static IMAGE_API_LINK(type, modelId, filename) {
    const types = [
      { type: "agenda", short: "AG" },
      { type: "event", short: "EV" },
      { type: "companySetting", short: "CS" },
      { type: "subscription", short: "SB" },
    ];

    const hasType = types.find(item => item.type == type);

    if (modelId && filename && hasType)
      return `${this.API_LINK}/${hasType.type.toLowerCase()}/${hasType.short}-${String(
        modelId
      ).padStart(5, "0")}/${filename}`;

    return null;
  }

  static EVENT_COMMUNICATION_OWNER_SEND_COMMS() {
    return `${this.API_LINK}/event-communication/owner-communication`;
  }

  static EVENT_GROUP_LINK(id) {
    return `${this.API_LINK}/event-groups/${id}`;
  }

  static CONTACT_COMPANY_PUBLIC_WEBSITE() {
    return `${this.API_LINK}/contacts/company-website`;
  }

  static UNAVAILABILITIES_LINK() {
    return `${this.API_LINK}/unavailability`;
  }
  static UNAVAILABILITY_LINK(id) {
    return `${this.API_LINK}/unavailability/${id}`;
  }
  static UNAVAILABILITY_DUPLICATE_LINK(id) {
    return `${this.API_LINK}/unavailability/${id}/duplicate`;
  }

  static SUBSCRIPTION_TYPES_LINK() {
    return `${this.API_LINK}/subscription-types`;
  }
  static SUBSCRIPTION_TYPES_PUBLIC_LINK(companyId) {
    return `${this.API_LINK}/subscription-types/public/${companyId}`;
  }
  static SUBSCRIPTION_TYPE_LINK(id) {
    return `${this.API_LINK}/subscription-types/${id}`;
  }
  static SUBSCRIPTION_TYPES_CHECK_ALLOW_CREATE_LINK() {
    return `${this.API_LINK}/subscription-types/check-allow-create`;
  }

  static CATEGORIES_LINK() {
    return `${this.API_LINK}/categories`;
  }
  static CATEGORY_LINK(id) {
    return `${this.API_LINK}/categories/${id}`;
  }

  static SUBSCRIPTIONS_LINK() {
    return `${this.API_LINK}/subscriptions`;
  }
  static SUBSCRIPTION_LINK(id) {
    return `${this.API_LINK}/subscriptions/${id}`;
  }
  static CREATE_PUBLIC_SUBSCRIPTION_LINK() {
    return `${this.API_LINK}/subscriptions/create-public-subscription`;
  }
  static SUBSCRIPTION_CHANGE_PAYMENT_STATUS_LINK(id) {
    return `${this.API_LINK}/subscriptions/${id}/update-payment-status`;
  }
  static SUBSCRIPTION_UPDATE_RECURRING_FOR_CLIENT_SPACE_LINK(id) {
    return `${this.API_LINK}/subscriptions/${id}/subscription-recurring-contact-space`;
  }

  static PAYMENT_CREATE_INTENT(companyId) {
    return `${this.API_LINK}/payment/create-intent/${companyId}`;
  }
  static PAYMENT_CREATE_UPDATE(companyId) {
    return `${this.API_LINK}/payment/update-intent/${companyId}`;
  }
  static PAYMENT_GET_CONFIG(companyId) {
    return `${this.API_LINK}/payment/config/${companyId}`;
  }
  static PAYMENT_GET_DATA() {
    return `${this.API_LINK}/payment/settings/stripe`;
  }
  static PAYMENT_STRIPE_CHANGE_STATUS() {
    return `${this.API_LINK}/payment/settings/stripe/payment`;
  }
  static PAYMENT_STRIPE_REMOVE_KEYS() {
    return `${this.API_LINK}/payment/settings/stripe/remove-keys`;
  }
  static PAYMENT_STRIPE_SAVE_KEYS() {
    return `${this.API_LINK}/payment/settings/stripe/save-keys`;
  }
  static PAYMENT_STRIPE_VALIDATE_KEYS() {
    return `${this.API_LINK}/payment/settings/stripe/validate-keys`;
  }
  static PAYMENT_STRIPE_INVALIDATE_KEYS() {
    return `${this.API_LINK}/payment/settings/stripe/invalidate-keys`;
  }

  static GET_SERVICE_PAYMENT_INFO = `${this.API_LINK}/payment/get-service-payment-info`;
  static SET_INTENT_SERVICE_PAYMENT = `${this.API_LINK}/payment/set-intent-service-payment`;
  static SET_PENDING_SERVICE_PAYMENT = `${this.API_LINK}/payment/set-pending-service-payment`;
  static SET_FAILED_SERVICE_PAYMENT = `${this.API_LINK}/payment/set-failed-service-payment`;
  static DELETE_INTENT_SERVICE_PAYMENT = `${this.API_LINK}/payment/delete-intent-service-payment`;

  static GET_SUBSCRIPTION_PAYMENT_INFO = `${this.API_LINK}/payment/get-subscription-payment-info`;
  static SET_INTENT_SUBSCRIPTION_PAYMENT = `${this.API_LINK}/payment/set-intent-subscription-payment`;
  static SET_PENDING_SUBSCRIPTION_PAYMENT = `${this.API_LINK}/payment/set-pending-subscription-payment`;
  static SET_FAILED_SUBSCRIPTION_PAYMENT = `${this.API_LINK}/payment/set-failed-subscription-payment`;
  static DELETE_INTENT_SUBSCRIPTION_PAYMENT = `${this.API_LINK}/payment/delete-intent-subscription-payment`;

  static ANNOUNCES_LINK() {
    return `${this.API_LINK}/announces`;
  }
  static ANNOUNCE_LINK(id) {
    return `${this.API_LINK}/announces/${id}`;
  }
  static ANNOUNCE_COUNT_SELECTED_PEOPLE_LINK() {
    return `${this.API_LINK}/event-objects/count-selected-people`;
  }

  static NOTIFICATION_INTERVALS_LINK() {
    return `${this.API_LINK}/notification-intervals`;
  }
  static NOTIFICATION_INTERVAL_LINK(id) {
    return `${this.API_LINK}/notification-intervals/${id}`;
  }

  static NOTIFICATION_INTERVAL_SETTINGS_LINK() {
    return `${this.API_LINK}/notification-interval-settings`;
  }

  static REPORTS_SUBSCRIPTIONS_LINK = `${this.API_LINK}/reports/subscriptions`;
  static REPORTS_SERVICES_LINK = `${this.API_LINK}/reports/services`;

  static CONTACT_HISTORY_LINK = `${this.API_LINK}/contact-history`;
}
