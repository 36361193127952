import axios from "axios";
import qs from "qs";

import { Links } from "@/api/links.js";

export class SubscriptionTypes {
  getAll(searchInput = null, pagination = true, page = 1, order = "desc", itemsPerPage = 10) {
    const params = {};

    if (!pagination) {
      params["pagination"] = false;
    } else {
      params["page"] = page;
      params["itemsPerPage"] = itemsPerPage;
    }

    if (searchInput) {
      params["searchInput"] = searchInput;
    }

    params["order"] = order;

    return axios.get(Links.SUBSCRIPTION_TYPES_LINK() + "?" + qs.stringify(params));
  }
  getPaged(itemsPerPage = 30, page = 1, order = "desc") {
    const params = {};
    params["page"] = page;
    params["itemsPerPage"] = itemsPerPage;
    params["order"] = order;
    return axios.get(Links.SUBSCRIPTION_TYPES_LINK() + "?" + qs.stringify(params));
  }
  fetch() {
    return axios.get(Links.SUBSCRIPTION_TYPES_LINK() + "?pagination=false");
  }
  get(id) {
    return axios.get(Links.SUBSCRIPTION_TYPE_LINK(id));
  }
  getPublicSubscriptions(companyId) {
    return axios.get(Links.SUBSCRIPTION_TYPES_PUBLIC_LINK(companyId));
  }
  post(item) {
    return axios.post(Links.SUBSCRIPTION_TYPES_LINK(), item);
  }
  put(id, item) {
    return axios.put(Links.SUBSCRIPTION_TYPE_LINK(id), item);
  }
  delete(id) {
    return axios.delete(Links.SUBSCRIPTION_TYPE_LINK(id));
  }
  checkAllowCreate() {
    return axios.get(Links.SUBSCRIPTION_TYPES_CHECK_ALLOW_CREATE_LINK());
  }
  getForFilters() {
    return axios.get(Links.SUBSCRIPTION_TYPES_LINK() + "/for-filters");
  }
}

export const APISubscriptionTypes = new SubscriptionTypes();
